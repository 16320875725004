<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from "sweetalert2";

export default {
    components: {
        Header,
        SideBar,
    },
    data() {
        return {
            selectedPaylink: null,
            paylinks: [], // Tableau pour stocker les paylinks
        };
    },
    mounted() {
        this.fetchPaylinks(); // Charge les paylinks au montage du composant
        this.startUpdatingExpiredPaylinks();
    },
    methods: {

        fetchPaylinks() {
            axios.get('/get-paylinks')
                .then((response) => {
                    this.paylinks = response.data; // Met à jour le tableau paylinks
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération des paylinks :", error);
                    Swal.fire({
                        icon: "error",
                        text: "Impossible de récupérer les paylinks.",
                    });
                });
        },

        activePaylink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }

            Swal.fire({
                title: "Êtes-vous sûr de vouloir valider le paylink ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Oui, valider",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(`/paylinks/${this.selectedPaylink}/activate`)
                        .then(() => {
                            Swal.fire({
                                icon: "success",
                                text: "Le Paylink a été activé avec succès.",
                            });
                            this.fetchPaylinks();
                        })
                        .catch((error) => {
                            Swal.fire({
                                icon: "error",
                                text: "Erreur lors de la validation du Paylink.",
                            });
                            console.error("Erreur lors de la validation:", error);
                        });
                }
            });
        },

        deactivatePaylink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }

            axios.post(`/paylinks/${this.selectedPaylink}/deactivate`)
                .then((response) => {
                    Swal.fire({
                        icon: "success",
                        text: "Le paylink a été désactivé avec succès.",
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    this.fetchPaylinks(); 
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        text: "Erreur lors de la désactivation du paylink.",
                    });
                    console.error("Erreur lors de la désactivation:", error);
                });
        },

        paylinkLink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }
            const paymentLink = `/paymentlink/${this.selectedPaylink}`;
            window.open(paymentLink, '_blank');
        },

        updateExpiredStatus() {
            axios.post('/paylinks/update-expired-status')
                .then(response => {
                    this.message = response.data.message;
                    console.log('Réponse :', response.data.message);
                })
                .catch(error => {
                    console.error('Erreur :', error.response ? error.response.data : error);
                });
        },

        startUpdatingExpiredPaylinks() {
            setInterval(() => {
                this.updateExpiredStatus();
            }, 5000);
        },

        goToPaymentTransactions() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }
            const transactionsLink = `/client/paylink-transactions/${this.selectedPaylink}`;
            window.location.href = transactionsLink;
        },

        sharePaylink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }
            const paymentLink = `https://afrikmoney.com/paymentlink/${this.selectedPaylink}`;
            const message = `Paylink de paiement : ${paymentLink}`;
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
        },
    },
};
</script>

<template>
<div>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">

                            <h5 class="mb-4">RECHERCHER</h5>
                            <hr>
                            <div class="row mb-4">
                                <div class="col-md-2">
                                    <label for="startDate">Date de début</label>
                                    <input type="date" id="startDate" class="form-control" v-model="startDate" />
                                </div>
                                <div class="col-md-2">
                                    <label for="endDate">Date de fin</label>
                                    <input type="date" id="endDate" class="form-control" v-model="endDate" />
                                </div>
                                <div class="col-md-2">
                                    <label for="statut">Statut</label>
                                    <select id="statut" class="form-control" v-model="status">
                                        <option value="">Tous</option>
                                        <option value="pending">En attente</option>
                                        <option value="approved">Succès</option>
                                        <option value="rejected">Échec</option>
                                        <option value="expired">Expiré</option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <label for="mode">Mode</label>
                                    <select id="mode" class="form-control" v-model="mode">
                                        <option value="">Tous</option>
                                        <option value="public">Public</option>
                                        <option value="privé">Privé</option>
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    <label for="objet">Objet</label>
                                    <select id="objet" class="form-control" v-model="objet">
                                        <option value="">Tous</option>
                                        <option value="Anniverssaire">Anniverssaire</option>
                                        <option value="Mariage">Mariage</option>
                                        <option value="Achat">Achat</option>
                                        <option value="Aide ou don">Aide ou don</option>
                                        <option value="Contribution">Contribution</option>
                                        <option value="Participation">Participation</option>
                                        <option value="Recouvrement">Recouvrement</option>
                                        <option value="Autre">Autre</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">GERER MES PAYLINKS</h5>
                            <hr>
                            <button type="button" class="btn btn-sm" @click="paylinkLink" style="background-color: #006666; color: white;">
                                <i class="bi bi-link-45deg"></i> Ouvrir la page du paylink
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="sharePaylink" style="background-color: #006666; color: white;">
                                <i class="bi bi-share"></i> Partager le lien du paylink
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="activePaylink" style="background-color: #006666; color: white;">
                                <i class="bi bi-x-circle"></i> Activer le Paylink
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="deactivatePaylink" style="background-color: #006666; color: white;">
                                <i class="bi bi-x-circle"></i> Désactiver le Paylink
                            </button>

                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">MES PAYLINKS</h5>
                            <hr>
                            <div class="table-responsive">
                                <table class="account-table table texte-sm">
                                    <thead style="background-color: #006666">
                                        <tr>
                                            <th scope="col" style="color: white">

                                            </th>
                                            <th scope="col" style="color: white" class="text-center">N°</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell text-center">CREER LE</th>
                                            <th scope="col" style="color: white" class="text-center">EXPIRATION</th>
                                            <th scope="col" style="color: white" class="text-center">SOLDE</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell text-center">NUMERO</th>
                                            <th scope="col" style="color: white" class="text-center">MODE</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell text-center">OBJET</th>
                                            <th scope="col" style="color: white" class="text-center">STATUT</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(paylink, index) in paylinks" :key="paylink.id" @click="selectedPaylink = paylink.id" @dblclick="paylinkLink()" :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                                <input type="radio" v-model="selectedPaylink" :value="paylink.id" name="paylinkSelection" />
                                            </td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ index + 1 }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ new Date(paylink.created_at).toLocaleDateString() }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ new Date(paylink.expiration_at).toLocaleDateString() }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.solde }} FCFA</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.withdrawal_number }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.mode }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.objet }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                                <span v-if="paylink.status === 'pending'" class="badge rounded-pill text-bg-warning">En attente</span>
                                                <span v-if="paylink.status === 'approved'" class="badge rounded-pill text-bg-success">Approuvé</span>
                                                <span v-if="paylink.status === 'rejected'" class="badge rounded-pill text-bg-danger">Rejeté</span>
                                                <span v-if="paylink.status === 'expired'" class="badge rounded-pill text-bg-secondary">Expiré</span>
                                                <span v-if="paylink.status === 'desactived'" class="badge rounded-pill text-bg-secondary">desactivé</span>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
</template>

<style scoped>
</style>
