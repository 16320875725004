<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from "sweetalert2";

export default {
    components: {
        Header,
        SideBar,
    },
    data() {
        return {
            loading: true,
            error: null,
            statistics: {
                paylinks_count: {
                    pending: 0,
                    approved: 0,
                    expired: 0,
                    rejected: 0
                },
                totaux_depots: {
                    MTN: {
                        montant: 0
                    },
                    MOOV: {
                        montant: 0
                    },
                    CELTIS: {
                        montant: 0
                    }
                },
                totaux_payouts: {
                    MTN: {
                        montant: 0,
                        count: 0
                    },
                    MOOV: {
                        montant: 0,
                        count: 0
                    },
                    CELTIS: {
                        montant: 0,
                        count: 0
                    }
                },
                balance: {
                    solde: 0,
                    retrait: 0,
                    depots: 0
                }
            },
        };
    },
    mounted() {
        this.fetchStatistics();
    },
    methods: {
        async fetchStatistics() {
            try {
                const response = await axios.get("/get-service-clients-stat");
                this.statistics = response.data;
                this.loading = false;
            } catch (error) {
                this.error = "Erreur lors de la récupération des statistiques.";
                console.error(error);
            }
        },
    },
};
</script>

<template>
<Header />
<div class="container-fluid">
    <div class="row">
        <SideBar />
        <main class="main-wrapper col-md-9 ms-sm-auto py-1 col-lg-10 px-md-4 border-start">
            <div class="row my-3 d-flex align-items-stretch">
                <!-- Balance and Transactions -->
                <div class="col-lg-7 col-12">

                    <div class="row my-1">
                        <div class="col-6">
                            <div class="custom-block custom-block-paylinks bg-white p-2 h-100">
                                <h5 class="mb-2 text-start fs-6">PAYLINKS</h5>
                                <div class="row text-start">
                                    <!-- Paylinks IN PROGRESS -->
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;"> EN ENTENTES </strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            : {{ statistics.paylinks_count.pending }}
                                        </div>
                                    </div>
                                    <!-- Valid Paylinks -->
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;"> VALIDES</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            : {{ statistics.paylinks_count.approved }}
                                        </div>
                                    </div>
                                    <!-- Expired Paylinks -->
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;"> EXPIRES</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            : {{ statistics.paylinks_count.expired }}
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;"> REJETES</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            : {{ statistics.paylinks_count.rejected }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="custom-block custom-block-paylinks bg-white p-2 h-100">
                                <h5 class="mb-2 text-start fs-6">DEPOTS</h5>
                                <div class="row text-start">
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;">MTN</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            : {{ statistics.totaux_depots.MTN.montant }}
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;">MOOV</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            : {{ statistics.totaux_depots.MOOV.montant }}
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;">CELTIS</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            : {{ statistics.totaux_depots.CELTIS.montant }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 mt-3">
                            <div class="custom-block custom-block-paylinks bg-white p-2 h-100">
                                <h5 class="mb-2 text-start fs-6">RETRAIT</h5>
                                <div class="row text-start">
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;">MTN</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            : {{ statistics.totaux_payouts.MTN.montant }}
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;">MOOV</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            : {{ statistics.totaux_payouts.MOOV.montant }}
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;">CELTIS</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            : {{ statistics.totaux_payouts.CELTIS.montant }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 mt-3">
                            <div class="custom-block custom-block-paylinks bg-white p-2 h-100">
                                <h5 class="mb-2 text-start fs-6">TRANSACTIONS</h5>
                                <div class="row text-start">
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;">MTN</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            :{{ statistics.totaux_payouts.MTN.count }}
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;">MOOV</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            :{{ statistics.totaux_payouts.MOOV.count }}
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="badge-paylink" style="background-color: #FFD014">
                                            <strong style="color: #000000; font-size: 0.8rem;">CELTIS</strong>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="badge-paylink">
                                            :{{ statistics.totaux_payouts.CELTIS.count }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="custom-block bg-white d-none d-md-block">
                        <h5 class="mb-3 text-start">DERNIERE ENGAGEMENTS</h5>
                        <hr> <!-- Horizontal line added here -->
                        <div class="table-responsive">
                            <table class="account-table table">
                                <thead style="background-color: #006666">
                                    <tr>
                                        <th scope="col" style="color: white">N°</th>
                                        <th scope="col" style="color: white">CLIENT</th>
                                        <th scope="col" style="color: white">E-MAIL</th>
                                        <th scope="col" style="color: white">DATE DE RAPPEL</th>
                                        <th scope="col" style="color: white">MODE DE RAPPEL</th>
                                        <th scope="col" style="color: white">ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- Affichage des transactions lorsqu'elles sont disponibles -->
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>

                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- Phrase avec l'icône pour voir toutes les transactions -->
                        <div class="text-end mt-3">
                            <a href="/service-client/alert-engagement" class="btn btn-link">
                                Voir toutes les engagements
                                <i class="fas fa-arrow-right"></i>
                            </a>
                        </div>
                    </div>

                </div>

                <div class="col-lg-5 col-6 d-none d-md-block">
                    <div class="custom-block custom-block-profile-front custom-block-profile text-center bg-white h-35">
                        <div class="d-flex justify-content-between align-items-start">
                            <!-- Transaction Details -->
                            <div class="text-start ms-1">
                                <div class="d-flex flex-column">
                                    <!-- Ligne pour TOTAL RETRAIT -->
                                    <div class="d-flex justify-content-between align-items-center mb-1">
                                        <h6 class="mb-0">TOTAL RETRAIT</h6>
                                        <a class="ms-2"><b>{{ statistics.balance.retrait }}</b></a>
                                    </div>
                                    <!-- Ligne pour TOTAL DEPOT -->
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h6 class="mb-0">TOTAL DEPOT</h6>
                                        <a class="ms-2"><b>{{ statistics.balance.solde }}</b></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row my-3">
                        <div class="col-lg-12 col-12">
                            <div class="custom-block bg-white">
                                <h5 class="mb-4">DERNIER PAYLINKS</h5>
                                <hr> <!-- Horizontal line added here -->
                                <div class="table-responsive">
                                    <table class="account-table table texte-sm">
                                        <thead style="background-color: #006666">
                                            <tr>
                                                <th scope="col" style="color: white">N°</th>
                                                <th scope="col" style="color: white" class="d-none d-md-table-cell">CREER LE</th>
                                                <th scope="col" style="color: white">SOLDE</th>
                                                <th scope="col" style="color: white">MODE</th>
                                                <th scope="col" style="color: white">STATUT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Loading -->
                <div v-if="loading" class="text-center my-5">
                    <p>Chargement des données...</p>
                </div>
                <!-- Error -->
                <div v-if="error" class="text-center my-5">
                    <p>{{ error }}</p>
                </div>
            </div>
        </main>
    </div>
</div>
</template>

<style scoped>
.account-table {
    border-collapse: collapse;
    width: 100%;
}
</style>
