<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from "sweetalert2";

export default {
    components: {
        Header,
        SideBar
    },
    data() {
        return {
            selectedPaylink: null,
            step: 1,
            formData: {
                title: "",
                objet: "",
                montant_min: "",
                fees: "",
                description: "",
                mode: "",
                expiration_at: "",
                withdrawal_number: "",
                image: null,
                client_id: "",
            },
            paylinks: [],
            imagePreview: null,
            paylinku: {
                title: '',
                objet: '',
                mode: 'public',
                montant_min: '',
                fees: '',
                description: '',
                expiration_at: '',
                withdrawal_number: '',
                image: null,
            },
            currentPage: 1,
            totalPages: 1,
            startDate: "",
            endDate: "",
            status: "",
            mode: "",
            objet: "",
        };
    },
    watch: {
        startDate() {
            this.fetchPaylinks();
        },
        endDate() {
            this.fetchPaylinks();
        },
        status() {
            this.fetchPaylinks();
        },
        mode() {
            this.fetchPaylinks();
        },
        objet() {
            this.fetchPaylinks();
        }
    },
    mounted() {
        this.startUpdatingExpiredPaylinks();
        this.fetchPaylinks();
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            try {
                const userData = JSON.parse(userDataString);
                this.formData.client_id = userData.additional_info.id;
            } catch (error) {
                console.error(
                    "Erreur lors du parsing de userData depuis sessionStorage:",
                    error
                );
            }
        }
    },
    methods: {
        fetchPaylinks(page = 1) {
            const clientId = this.getClientIdFromSession();
            if (clientId) {
                axios
                    .get(`/paylinks/${clientId}`, {
                        params: {
                            page: page,
                            startDate: this.startDate,
                            endDate: this.endDate,
                            status: this.status,
                            mode: this.mode,
                            objet: this.objet
                        },
                    })
                    .then((response) => {
                        this.paylinks = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.totalPages = response.data.last_page;
                    })
                    .catch((error) => {
                        console.error(
                            "Erreur lors de la récupération des paylinks:",
                            error
                        );
                    });
            }
        },

        deactivatePaylink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }

            axios.post(`/paylinks/${this.selectedPaylink}/deactivate`)
                .then((response) => {
                    Swal.fire({
                        icon: "success",
                        text: "Le paylink a été désactivé avec succès.",
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    this.fetchPaylinks();
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        text: "Erreur lors de la désactivation du paylink.",
                    });
                    console.error("Erreur lors de la désactivation:", error);
                });
        },

        editPaylink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }

            axios.get(`/show-paylinks/${this.selectedPaylink}`)
                .then((response) => {
                    this.paylinku = response.data;

                    // Formater la date avant de l'afficher
                    if (this.paylinku.expiration_at) {
                        this.paylinku.expiration_at = this.formatDateForInput(this.paylinku.expiration_at);
                    }

                    $('#editPaylinkModal').modal('show');
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération du paylink:", error);
                    Swal.fire({
                        icon: "error",
                        text: "Erreur lors de la récupération des informations.",
                    });
                });
        },

        formatDateForInput(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}-${month}-${day}`;
        },

        // Met à jour les informations du paylink
        updatePaylink() {
            const updatedPaylink = new FormData();
            updatedPaylink.append('title', this.paylinku.title);
            updatedPaylink.append('objet', this.paylinku.objet);
            updatedPaylink.append('mode', this.paylinku.mode);
            updatedPaylink.append('montant_min', this.paylinku.montant_min);
            updatedPaylink.append('fees', this.paylinku.fees);
            updatedPaylink.append('description', this.paylinku.description);
            updatedPaylink.append('expiration_at', this.paylinku.expiration_at);
            updatedPaylink.append('withdrawal_number', this.paylinku.withdrawal_number);
            updatedPaylink.append('_method', 'put');
            if (this.paylinku.image) {
                updatedPaylink.append('image', this.paylinku.image);
            }

            axios.post(`/update-paylinks/${this.selectedPaylink}`, updatedPaylink)
                .then((response) => {
                    Swal.fire({
                        icon: "success",
                        text: "Paylink mis à jour avec succès.",
                    });
                    $('#editPaylinkModal').modal('hide');
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        text: "Erreur lors de la mise à jour du paylink.",
                    });
                    console.error("Erreur lors de la mise à jour:", error);
                });
        },

        // Gestion de l'image sélectionnée
        handleImageChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.paylinku.image = file;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        paylinkLink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }
            const paymentLink = `/paymentlink/${this.selectedPaylink}`;
            window.open(paymentLink, '_blank');
        },

        updateExpiredStatus() {
            axios.post('/paylinks/update-expired-status')
                .then(response => {
                    this.message = response.data.message;
                    console.log('Réponse :', response.data.message);
                })
                .catch(error => {
                    console.error('Erreur :', error.response ? error.response.data : error);
                });
        },

        startUpdatingExpiredPaylinks() {
            setInterval(() => {
                this.updateExpiredStatus();
            }, 5000);
        },

        goToPaymentTransactions() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }
            const transactionsLink = `/client/paylink-transactions/${this.selectedPaylink}`;
            window.location.href = transactionsLink;
        },

        sharePaylink() {
            if (!this.selectedPaylink) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez sélectionner un paylink.",
                });
                return;
            }
            const paymentLink = `https://afrikmoney.com/paymentlink/${this.selectedPaylink}`;
            const message = `Paylink de paiement : ${paymentLink}`;
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
            window.open(whatsappUrl, '_blank');
        },

        getClientIdFromSession() {
            const userDataString = sessionStorage.getItem("userData");
            if (userDataString) {
                try {
                    const userData = JSON.parse(userDataString);
                    return userData.additional_info.id;
                } catch (error) {
                    console.error(
                        "Erreur lors du parsing de userData depuis sessionStorage:",
                        error
                    );
                }
            }
            return null;
        },
        goToPage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.fetchPaylinks(page);
            }
        },
        nextStep() {
            if (this.step === 1 && (!this.formData.objet || !this.formData.montant_min)) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez remplir tous les champs requis pour cette étape.",
                });
                return;
            }
            if (this.step === 2 && (!this.formData.expiration_at || !this.formData.withdrawal_number)) {
                Swal.fire({
                    icon: "error",
                    text: "Veuillez remplir tous les champs requis pour cette étape.",
                });
                return;
            }
            this.step++;
        },
        prevStep() {
            this.step--;
        },
        handleImageUpload(event) {
            this.formData.image = event.target.files[0];
        },
        submitForm() {
            const formData = new FormData();
            for (const key in this.formData) {
                formData.append(key, this.formData[key]);
            }
            axios
                .post("/add-paylinks", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                })
                .then(() => {
                    Swal.fire({
                        icon: "success",
                        title: "Paylink créé avec succès !",
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    }).then(() => {
                        window.location.reload();
                    });
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur lors de la soumission",
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                });
        },
    },
};
</script>

<template>
<div>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">

                            <h5 class="mb-4">RECHERCHER</h5>
                            <hr>
                            <div class="row mb-4">
                                <div class="col-md-2">
                                    <label for="startDate">Date de début</label>
                                    <input type="date" id="startDate" class="form-control" v-model="startDate" />
                                </div>
                                <div class="col-md-2">
                                    <label for="endDate">Date de fin</label>
                                    <input type="date" id="endDate" class="form-control" v-model="endDate" />
                                </div>
                                <div class="col-md-2">
                                    <label for="statut">Statut</label>
                                    <select id="statut" class="form-control" v-model="status">
                                        <option value="">Tous</option>
                                        <option value="pending">En attente</option>
                                        <option value="approved">Succès</option>
                                        <option value="rejected">Échec</option>
                                        <option value="expired">Expiré</option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <label for="mode">Mode</label>
                                    <select id="mode" class="form-control" v-model="mode">
                                        <option value="">Tous</option>
                                        <option value="public">Public</option>
                                        <option value="privé">Privé</option>
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    <label for="objet">Objet</label>
                                    <select id="objet" class="form-control" v-model="objet">
                                        <option value="">Tous</option>
                                        <option value="Anniverssaire">Anniverssaire</option>
                                        <option value="Mariage">Mariage</option>
                                        <option value="Achat">Achat</option>
                                        <option value="Aide ou don">Aide ou don</option>
                                        <option value="Contribution">Contribution</option>
                                        <option value="Participation">Participation</option>
                                        <option value="Recouvrement">Recouvrement</option>
                                        <option value="Autre">Autre</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">GERER MES PAYLINKS</h5>
                            <hr>
                            <button type="button" class="btn btn-sm" data-bs-toggle="modal" data-bs-target="#paylinkModal" style="background-color: #006666; color: white;">
                                <i class="bi bi-plus-circle"></i> Nouveau Paylink
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="paylinkLink" style="background-color: #006666; color: white;">
                                <i class="bi bi-link-45deg"></i> Ouvrir la page du paylink
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="sharePaylink" style="background-color: #006666; color: white;">
                                <i class="bi bi-share"></i> Partager le lien du paylink
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="editPaylink" data-bs-toggle="modal" data-bs-target="#editPaylinkModal" style="background-color: #006666; color: white;">
                                <i class="bi bi-pencil"></i> Modifier le Paylink
                            </button>
                            <span>&nbsp;</span>
                            <button type="button" class="btn btn-sm" @click="deactivatePaylink" style="background-color: #006666; color: white;">
                                <i class="bi bi-x-circle"></i> Désactiver le Paylink
                            </button>

                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">MES PAYLINKS</h5>
                            <hr>
                            <div class="table-responsive">
                                <table class="account-table table texte-sm">
                                    <thead style="background-color: #006666">
                                        <tr>
                                            <th scope="col" style="color: white">

                                            </th>
                                            <th scope="col" style="color: white" class="text-center">N°</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell text-center">CREER LE</th>
                                            <th scope="col" style="color: white" class="text-center">EXPIRATION</th>
                                            <th scope="col" style="color: white" class="text-center">SOLDE</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell text-center">NUMERO</th>
                                            <th scope="col" style="color: white" class="text-center">MODE</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell text-center">OBJET</th>
                                            <th scope="col" style="color: white" class="text-center">STATUT</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(paylink, index) in paylinks" :key="paylink.id" @click="selectedPaylink = paylink.id" @dblclick="paylinkLink()" :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                                <input type="radio" v-model="selectedPaylink" :value="paylink.id" name="paylinkSelection" />
                                            </td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ (currentPage - 1) * 15 + index + 1 }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ new Date(paylink.created_at).toLocaleDateString() }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ new Date(paylink.expiration_at).toLocaleDateString() }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.solde }} FCFA</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.withdrawal_number }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.mode }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.objet }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                                <span v-if="paylink.status === 'pending'" class="badge rounded-pill text-bg-warning">En attente</span>
                                                <span v-if="paylink.status === 'approved'" class="badge rounded-pill text-bg-success">Approuvé</span>
                                                <span v-if="paylink.status === 'rejected'" class="badge rounded-pill text-bg-danger">Rejeté</span>
                                                <span v-if="paylink.status === 'expired'" class="badge rounded-pill text-bg-primary">Expiré</span>
                                                <span v-if="paylink.status === 'desactived'" class="badge rounded-pill text-bg-secondary">Expiré</span>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                            <nav aria-label="Page navigation">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                        <a class="page-link" @click="goToPage(currentPage - 1)" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li class="page-item" :class="{ active: currentPage === page }" v-for="page in totalPages" :key="page">
                                        <a class="page-link" @click="goToPage(page)" href="#">{{page}}</a>
                                    </li>
                                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                        <a class="page-link" @click="goToPage(currentPage + 1)" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>

                            <div class="modal fade" id="paylinkModal" tabindex="-1" aria-labelledby="paylinkModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header" style="background-color: rgb(0, 102, 102)">
                                            <h5 class="modal-title" id="paylinkModalLabel" style="color: #ffffff">
                                                Nouveau Paylink
                                            </h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>

                                        <div class="modal-body">
                                            <div class="alert alert-info" role="alert">
                                                Un "Paylink" est un lien de paiement sécurisé que vous
                                                pouvez partager avec vos clients. Lorsqu'un client
                                                clique sur ce lien, il est redirigé vers une page de
                                                paiement où il peut régler le montant spécifié. Cela
                                                facilite la collecte de paiements en ligne sans avoir
                                                à configurer une page de paiement complexe.
                                            </div>

                                            <form @submit.prevent="submitForm">
                                                <div v-if="step === 1">
                                                    <div class="form-group">
                                                        <div class="mb-3">
                                                            <label for="paylinkTitle" class="form-label">Titre du Paylink</label>
                                                            <input v-model="formData.title" type="text" class="form-control" id="paylinkTitle" placeholder="Entrez le titre du Paylink">
                                                        </div>

                                                        <label for="objet">
                                                            L'objet de votre PayLink (Mariage, Anniverssaire, etc..).
                                                        </label><br><br>
                                                        <select v-model="formData.objet" class="form-control" id="objet" required>
                                                            <option disabled value="">Sélectionnez</option>
                                                            <option value="Anniverssaire">Anniverssaire</option>
                                                            <option value="Mariage">Mariage</option>
                                                            <option value="Achat">Achat</option>
                                                            <option value="Aide ou don">Aide ou don</option>
                                                            <option value="Contribution">Cotisation</option>
                                                            <option value="Participation">Participation</option>
                                                            <option value="Recouvrement">Recouvrement</option>
                                                            <option value="Autre">Autre</option>
                                                        </select>
                                                    </div><br>

                                                    <div class="form-group">
                                                        <label for="mode">Préciser si votre PayLink est accessible à un groupe restreint de personnes ou public.</label><br><br>
                                                        <select v-model="formData.mode" class="form-control" id="mode" required>
                                                            <option disabled value="">
                                                                Choisir un mode
                                                            </option>
                                                            <option value="public">Public</option>
                                                            <option value="privé">Privé</option>
                                                        </select>
                                                    </div> <br />
                                                    <div class="form-group">
                                                        <label for="description">Brève description de votre PayLink qui présente vos attentes et objectifs visés auprès de vos utilisateurs (au plus 150 caractères)</label><br><br>
                                                        <textarea v-model="formData.description" class="form-control" id="description" placeholder="Description"></textarea>
                                                    </div> <br />
                                                    <div class="form-group">
                                                        <label for="montant_min">
                                                            Indiquez le montant minimum à payer.</label><br><br>
                                                        <input v-model="formData.montant_min" type="number" class="form-control" id="montant_min" />
                                                    </div>
                                                    <!-- Nouveau champ ajouté ici -->
                                                    <div class="form-group">
                                                        <label for="fees">
                                                            Indiquez les frais applicables.</label><br><br>
                                                        <select v-model="formData.fees" class="form-control" id="fees" required>
                                                            <option value="3">3%</option>
                                                            <option value="3.5">3.5%</option>
                                                            <option value="4">4%</option>
                                                            <option value="4.5">4.5%</option>
                                                            <option value="5">5%</option>
                                                            <option value="5.5">5.5%</option>
                                                            <option value="6">6%</option>
                                                        </select>
                                                    </div>
                                                    <button @click="nextStep" class="btn btn-primary mt-3">
                                                        Suivant
                                                    </button>
                                                </div>

                                                <div v-if="step === 2">
                                                    <div class="form-group">
                                                        <label for="expiration_at">
                                                            Indiquez jusqu'à quelle date ce lien de paiement
                                                            sera valide.</label><br><br>
                                                        <input v-model="formData.expiration_at" type="date" class="form-control" id="expiration_at" />
                                                    </div> <br />
                                                    <div class="form-group">
                                                        <label for="withdrawal_number">Indiquez le numéro de retrait associées à ce
                                                            paylink.</label><br><br>
                                                        <input v-model="formData.withdrawal_number" type="text" class="form-control" id="withdrawal_number" placeholder="Numéro de Retrait" required />
                                                    </div>
                                                    <button @click="prevStep" class="btn btn-secondary mt-3">
                                                        Précédent
                                                    </button>
                                                    <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                                    <button @click="nextStep" class="btn btn-primary mt-3">
                                                        Suivant
                                                    </button>
                                                </div>
                                                <div v-if="step === 3">
                                                    <div class="form-group">
                                                        <label for="image">Vous pouvez ajouter une image ou un logo avec
                                                            le lien de paiement.</label><br><br>
                                                        <input @change="handleImageUpload" type="file" class="form-control" id="image" />
                                                    </div> <br />
                                                    <button @click="prevStep" class="btn btn-secondary mt-3">
                                                        Précédent
                                                    </button>
                                                    <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                                                    <button type="submit" class="btn btn-primary mt-3">
                                                        Soumettre
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal pour Modifier le Paylink -->
                            <div class="modal fade" id="editPaylinkModal" tabindex="-1" aria-labelledby="editPaylinkModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg">
                                    <div class="modal-content">
                                        <div class="modal-header" style="background-color: rgb(0, 102, 102)">
                                            <h5 class="modal-title" id="paylinkModalLabel" style="color: #ffffff">Modifier le Paylink</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <form>
                                                <div class="mb-3">
                                                    <label for="paylinkTitle" class="form-label">Titre du Paylink</label>
                                                    <input type="text" class="form-control" id="paylinkTitle" v-model="paylinku.title" placeholder="Entrez le titre du Paylink">
                                                </div>
                                                <div class="mb-3">
                                                    <label for="paylinkObject" class="form-label">Objet</label>
                                                    <select class="form-control" id="objet" v-model="paylinku.objet" required>
                                                        <option disabled value="">Sélectionnez</option>
                                                        <option value="Anniverssaire">Anniverssaire</option>
                                                        <option value="Mariage">Mariage</option>
                                                        <option value="Achat">Achat</option>
                                                        <option value="Aide ou don">Aide ou don</option>
                                                        <option value="Contribution">Contribution</option>
                                                        <option value="Participation">Participation</option>
                                                        <option value="Recouvrement">Recouvrement</option>
                                                        <option value="Autre">Autre</option>
                                                    </select>
                                                </div>

                                                <div class="mb-3">
                                                    <label for="paylinkMode" class="form-label">Mode</label>
                                                    <select class="form-control" id="paylinkMode" v-model="paylinku.mode">
                                                        <option value="public">Public</option>
                                                        <option value="privé">Privé</option>
                                                    </select>
                                                </div>

                                                <div class="mb-3">
                                                    <label for="paylinkMinAmount" class="form-label">Montant Min</label>
                                                    <input type="number" class="form-control" id="paylinkMinAmount" v-model="paylinku.montant_min" placeholder="Entrez le montant minimum">
                                                </div>
                                                <div class="mb-3">
                                                    <label for="paylinkTax" class="form-label">Taux</label>
                                                    <input type="number" class="form-control" id="paylinkTax" v-model="paylinku.fees" placeholder="Entrez le taux">
                                                </div>
                                                <div class="mb-3">
                                                    <label for="paylinkDescription" class="form-label">Description</label>
                                                    <textarea class="form-control" id="paylinkDescription" v-model="paylinku.description" rows="3" placeholder="Entrez une description pour le Paylink"></textarea>
                                                </div>
                                                <div class="mb-3">
                                                    <label for="paylinkExpiration" class="form-label">Date d'expiration</label>
                                                    <input type="date" class="form-control" id="expiration_at" v-model="paylinku.expiration_at" />
                                                </div>

                                                <div class="mb-3">
                                                    <label for="paylinkWithdrawalNumber" class="form-label">Numéro de retrait</label>
                                                    <input type="text" class="form-control" id="paylinkWithdrawalNumber" v-model="paylinku.withdrawal_number" placeholder="Entrez le numéro de retrait">
                                                </div>
                                                <div class="mb-3">
                                                    <label for="paylinkImage" class="form-label">Image</label>
                                                    <input type="file" class="form-control" id="paylinkImage" @change="handleImageChange">
                                                </div>

                                                <!-- Aperçu de l'image sélectionnée -->
                                                <div v-if="imagePreview" class="mt-3">
                                                    <label for="imagePreview" class="form-label">Aperçu de l'image :</label>
                                                    <img :src="imagePreview" alt="Aperçu de l'image" class="img-fluid" style="max-width: 200px; max-height: 200px;">
                                                </div>

                                            </form>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                                            <button type="button" class="btn btn-primary" @click="updatePaylink">Enregistrer les modifications</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
</template>

<style scoped>
</style>
