<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from 'sweetalert2';

export default {
    components: {
        Header,
        Footer,
        SideBar
    },
    data() {
        return {
            paylinkRequests: []
        };
    },
    mounted() {
        this.fetchPaylinkRequests();
    },
    methods: {
        async fetchPaylinkRequests() {
            try {
                const response = await axios.get("/paylink-request");
                this.paylinkRequests = response.data.paylink_requests.data;
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Erreur',
                    text: 'Impossible de récupérer les données.',
                });
            }
        },

        async validatePaylinkRequest(requestId) {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir valider le paylink ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, valider',
                cancelButtonText: 'Annuler',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await axios.put(`/paylink-requests/${requestId}/successful`);
                        Swal.fire('Validée!', 'Le Paylink a été validée.', 'success');
                        this.fetchPaylinkRequests();
                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur',
                            text: 'Une erreur est survenue lors de la validation.',
                        });
                    }
                }
            });
        },

        async refusePaylinkRequest(requestId) {
            Swal.fire({
                title: 'Êtes-vous sûr de vouloir refuser le paylink ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Oui, refuser',
                cancelButtonText: 'Annuler',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await axios.put(`/paylink-requests/${requestId}/failed`);
                        Swal.fire('Refusée!', 'Le Paylink a été refusée.', 'success');
                        this.fetchPaylinkRequests();
                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erreur',
                            text: 'Une erreur est survenue lors du refus.',
                        });
                    }
                }
            });
        },
    },
};
</script>

<template>
<div>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row my-4">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">Paylinks</h5>
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666;">
                                        <tr>
                                            <th scope="col" style="color: white; text-align: center;">N°</th>
                                            <th scope="col" style="color: white; text-align: center;">CLIENT</th>
                                            <th scope="col" style="color: white; text-align: center;">PAYLINK</th>
                                            <th scope="col" style="color: white; text-align: center;">OBJET</th>
                                            <th scope="col" style="color: white; text-align: center;">MODE</th>
                                            <th scope="col" style="color: white; text-align: center;">MONTANT</th>
                                            <th scope="col" style="color: white; text-align: center;">STATUT</th>
                                            <th scope="col" style="color: white; text-align: center;">ACTION</th>
                                        </tr>

                                    </thead>
                                    <tbody v-if="paylinkRequests.length > 0">
                                        <tr v-for="(request, index) in paylinkRequests" :key="request.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ request.client.nom }}</td>
                                            <td>{{ request.title }}</td>
                                            <td>{{ request.objet }}</td>
                                            <td>{{ request.mode }}</td>
                                            <td>{{ request.montant_min }}</td>
                                            <td>
                                                <span v-if="request.status === 'pending'" class="badge rounded-pill text-bg-warning">En attente</span>
                                                <span v-if="request.status === 'approved'" class="badge rounded-pill text-bg-success">Approuvé</span>
                                                <span v-if="request.status === 'rejected'" class="badge rounded-pill text-bg-danger">Rejeté</span>
                                                <span v-if="request.status === 'expired'" class="badge rounded-pill text-bg-secondary">Expiré</span>
                                            </td>
                                            <td>
                                                <!-- Affiche les boutons uniquement si le statut est 'pending' -->
                                                <template v-if="request.status === 'pending'">
                                                    <button class="btn btn-success" @click="validatePaylinkRequest(request.id)">Valider</button>
                                                    <span>&nbsp;</span>
                                                    <button class="btn btn-danger" @click="refusePaylinkRequest(request.id)">Refuser</button>
                                                </template>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="7" class="text-center">
                                                <div class="alert alert-warning d-flex justify-content-center align-items-center" role="alert">
                                                    <i class="fas fa-trash-alt mr-2"></i>
                                                    <span>Aucune demande de remboursement disponible.</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    </div>
</div>
</template>
