<script>
import axios from "@/axs/axios.js";
import Header from "@/components/Header.vue";
import SideBar from "@/components/SideBar.vue";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";

export default {
    components: {
        Header,
        SideBar,
    },
    data() {
        return {
            selectedPaylink: null,
            transactions: [],
            filteredTransactions: [],
            loading: false,
            currentPage: 1,
            totalPages: 1,
            perPage: 15,
            clientId: "",
            startDate: "",
            endDate: "",
            selectedOperateur: "",
            selectedStatut: "",
            paylinks: [],
        };
    },
    mounted() {
        this.fetchPaylinks();
        const userDataString = sessionStorage.getItem("userData");
        if (userDataString) {
            try {
                const userData = JSON.parse(userDataString);
                this.clientId = userData.additional_info.id;
                this.loadTransactions();
            } catch (error) {
                console.error("Erreur lors du parsing de userData:", error);
            }
        }
    },
    watch: {
        startDate() {
            this.loadTransactions();
        },
        endDate() {
            this.loadTransactions();
        },
        selectedOperateur() {
            this.loadTransactions();
        },
        selectedStatut() {
            this.loadTransactions();
        },
        selectedPaylink() {
            this.filterTransactions();
        },
    },
    methods: {
        async loadTransactions(page = 1) {
            this.loading = true;
            try {
                if (this.clientId) {
                    const params = {
                        page,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        operateur: this.selectedOperateur,
                        statut: this.selectedStatut,
                    };
                    const response = await axios.get(`/transactions/client/${this.clientId}`, {
                        params
                    });
                    this.transactions = response.data.transactions.data;
                    this.currentPage = response.data.transactions.current_page;
                    this.totalPages = response.data.transactions.last_page;
                    this.filterTransactions();
                } else {
                    Swal.fire("Erreur", "Client ID introuvable", "error");
                }
            } catch (error) {
                console.error("Erreur lors du chargement des transactions:", error);
            } finally {
                this.loading = false;
            }
        },
        selectPaylink(paylink) {
            this.selectedPaylink = paylink;
            this.filterTransactions();
        },

        filterTransactions() {
            if (this.selectedPaylink) {
                this.filteredTransactions = this.transactions.filter(transaction => transaction.paylink_id === this.selectedPaylink);
            } else {
                this.filteredTransactions = this.transactions;
            }
            this.totalPages = Math.ceil(this.filteredTransactions.length / this.perPage);
            this.currentPage = 1;
        },
        goToPage(page) {
            if (page > 0 && page <= this.totalPages) {
                this.loadTransactions(page);
            }
        },
        exportToCSV() {
            Swal.fire({
                title: "Confirmer l'exportation",
                text: "Êtes-vous sûr de vouloir exporter ces données en CSV ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Exporter",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.isConfirmed) {
                    const header = ["N°", "DATE", "EXPÉDITEUR", "MONTANT", "GSM", "FONCTION", "PAIEMENT"].join(",");
                    const rows = this.filteredTransactions.map((transaction, index) => {
                        return [
                            index + 1,
                            moment(transaction.created_at).format("DD/MM/YYYY"),
                            transaction.nomdestinataire,
                            transaction.montant,
                            transaction.operateur,
                            transaction.fonction,
                            transaction.payment_type,
                        ].join(",");
                    });
                    const csvContent = [header, ...rows].join("\n");
                    const blob = new Blob([csvContent], {
                        type: "text/csv;charset=utf-8;"
                    });
                    const link = document.createElement("a");
                    if (link.download !== undefined) {
                        const url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", "transactions.csv");
                        link.style.visibility = "hidden";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                    Swal.fire("Succès !", "L'exportation en CSV a réussi.", "success");
                }
            });
        },
        exportToPDF() {
            Swal.fire({
                title: "Exportation PDF",
                text: "Êtes-vous sûr de vouloir exporter ces données en PDF ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Exporter",
                cancelButtonText: "Annuler",
            }).then((result) => {
                if (result.isConfirmed) {
                    const doc = new jsPDF();
                    let totalPages = 1;

                    // En-tête du document
                    doc.setFillColor(0, 102, 102);
                    doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");
                    const imgDataLeft = "../images/LOGOAFRICAMONEY.png";
                    doc.addImage(imgDataLeft, "JPEG", 25, 10, 23, 23);
                    const middleX = doc.internal.pageSize.width / 2;
                    doc.setTextColor("#ffffff");
                    doc.text("RELEVE DES TRANSACTIONS", middleX, 17.5, {
                        align: "center"
                    });

                    // Période
                    if (this.startDate && this.endDate) {
                        const formattedStartDate = moment(this.startDate).format("DD/MM/YYYY");
                        const formattedEndDate = moment(this.endDate).format("DD/MM/YYYY");
                        const periodText = `PÉRIODE: du ${formattedStartDate} au ${formattedEndDate}`;
                        doc.text(periodText, middleX, 27.5, {
                            align: "center"
                        });
                    }
                    doc.setTextColor(0, 0, 0);

                    // Table des données
                    const tableData = this.filteredTransactions.map((transaction, index) => [
                        index + 1,
                        moment(transaction.created_at).format("DD/MM/YYYY"),
                        transaction.nomdestinataire,
                        transaction.montant,
                        transaction.operateur,
                        transaction.fonction,
                        transaction.payment_type,
                    ]);

                    doc.autoTable({
                        head: [
                            ["N°", "DATE", "EXPÉDITEUR", "MONTANT", "GSM", "FONCTION", "PAIEMENT"]
                        ],
                        body: tableData,
                        headStyles: {
                            fillColor: [0, 102, 102],
                            textColor: "#ffffff",
                            fontSize: 8,
                        },
                        bodyStyles: {
                            fontSize: 7,
                        },
                        startY: 40,
                        didDrawPage: function (data) {
                            totalPages = doc.internal.getNumberOfPages();
                            doc.setFontSize(6);
                            doc.text(`Page ${data.pageNumber} sur ${totalPages}`, 14, doc.internal.pageSize.height - 10);
                            const currentDate = new Date();
                            const dateString = currentDate.toLocaleDateString();
                            const timeString = currentDate.toLocaleTimeString();
                            doc.text(`imprimé le: ${dateString} à ${timeString}`, 14, doc.internal.pageSize.height - 5);
                        },
                    });

                    doc.save("releve_des_transactions.pdf");
                    Swal.fire("Succès !", "L'exportation en PDF a réussi.", "success");
                }
            });
        },
        fetchPaylinks(page = 1) {
            const clientId = this.getClientIdFromSession();
            if (clientId) {
                axios
                    .get(`/paylinks/approved/${clientId}`)
                    .then((response) => {
                        this.paylinks = response.data.data;
                        this.currentPage = response.data.current_page;
                        this.totalPages = response.data.last_page;
                    })
                    .catch((error) => {
                        console.error("Erreur lors de la récupération des paylinks:", error);
                    });
            }
        },

        getClientIdFromSession() {
            const userDataString = sessionStorage.getItem("userData");
            if (userDataString) {
                try {
                    const userData = JSON.parse(userDataString);
                    return userData.additional_info.id;
                } catch (error) {
                    console.error("Erreur lors du parsing de userData depuis sessionStorage:", error);
                }
            }
            return null;
        },
    },
};
</script>

<template>
<div>
    <Header />
    <div class="container-fluid">
        <div class="row">
            <SideBar />
            <main class="main-wrapper col-md-9 ms-sm-auto py-4 col-lg-10 px-md-4 border-start">
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">

                            <h5 class="mb-4">RECHERCHER</h5>
                            <hr> <!-- Horizontal line added here -->
                            <div class="row mb-4">
                                <div class="col-md-2">
                                    <label for="startDate">Date de début</label>
                                    <input type="date" id="startDate" class="form-control" v-model="startDate" />
                                </div>
                                <div class="col-md-2">
                                    <label for="endDate">Date de fin</label>
                                    <input type="date" id="endDate" class="form-control" v-model="endDate" />
                                </div>
                                <div class="col-md-2">
                                    <label for="operateur">Opérateur</label>
                                    <select id="operateur" class="form-control" v-model="selectedOperateur">
                                        <option value="">Tous</option>
                                        <option value="MTN">MTN</option>
                                        <option value="MOOV">MOOV</option>
                                        <option value="CELTIS">CELTIS</option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <label for="fonction">Fonction</label>
                                    <select id="fonction" class="form-control" v-model="selectedFonction">
                                        <option value="Présidents et Membres d'Institution">Président et Membre d'Institution</option>
                                        <option value="Ministres">Ministre</option>
                                        <option value="Députés">Député</option>
                                        <option value="Maires">Maire</option>
                                        <option value="Adjoints au Maire">Adjoint au Maire</option>
                                        <option value="Chefs d'Arrondissement">Chefs d'Arrondissement</option>
                                        <option value="Présidents de commission">Président de commission</option>
                                        <option value="Conseillers Communaux">Conseiller Communal</option>
                                        <option value="Préfets">Préfet</option>
                                        <option value="Directeurs Généraux">Directeur Général</option>
                                        <option value="Directeurs Généraux Adjoints">Directeur Général Adjoint</option>
                                        <option value="Secrétaires Généraux">Secrétaire Général</option>
                                        <option value="Secrétaires Généraux Adjoints">Secrétaire Généraux Adjoint</option>
                                        <option value="Conseillers Techniques">Conseiller Technique</option>
                                        <option value="Chargés de Mission">Chargé de Mission</option>
                                        <option value="Directeurs centraux et départementaux">Directeur central et départemental</option>
                                        <option value="Chefs Service">Chef Service</option>
                                        <option value="Chefs Division">Chef Division</option>
                                        <option value="Militants">Militant</option>
                                        <option value="Sympathisants">Sympathisant</option>
                                        <option value="Autres">Autre</option>
                                    </select>
                                </div>

                                <div class="col-md-2">
                                    <label for="paiementtype">Paiement</label>
                                    <select id="paiementtype" class="form-control" v-model="selectedPaiement_type">
                                        <option value="MTN">Cotisation</option>
                                        <option value="MOOV">Contribution</option>
                                        <option value="CELTIS">Souscription</option>
                                    </select>
                                </div>
                                <div class="col-md-2">
                                    <label for="statut">Statut</label>
                                    <select id="statut" class="form-control" v-model="selectedStatut">
                                        <option value="">Tous</option>
                                        <option value="SUCCESSFUL">Succès</option>
                                        <option value="FAILED">Échec</option>
                                        <option value="PENDING">En attente</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">GERER MES DEPOTS</h5>
                            <hr> <!-- Horizontal line added here -->
                            <button @click="exportToCSV" class="btn btn-sm" style="background-color: #006666; color: white;">
                                <i class="bi bi-file-earmark-spreadsheet"></i> Imprimer le relevé (CSV)
                            </button>
                            <span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span>
                            <button @click="exportToPDF" class="btn btn-sm" style="background-color: #006666; color: white;">
                                <i class="bi bi-file-earmark-pdf"></i> Imprimer le relevé (PDF)
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">MES PAYLINKS</h5>
                            <hr> <!-- Horizontal line added here -->
                            <div class="table-responsive">
                                <table class="account-table table texte-sm">
                                    <thead style="background-color: #006666">
                                        <tr>
                                            <th scope="col" style="color: white" class="text-center"></th>
                                            <th scope="col" style="color: white" class="text-center">N°</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell text-center">CREER LE</th>
                                            <th scope="col" style="color: white" class="text-center">EXPIRATION</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell text-center">TITRE</th>
                                            <th scope="col" style="color: white" class="text-center">MODE</th>
                                            <th scope="col" style="color: white" class="d-none d-md-table-cell text-center">OBJET</th>
                                            <th scope="col" style="color: white" class="text-center">STATUT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(paylink, index) in paylinks" :key="paylink.id" @click="selectPaylink(paylink.id)" :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                                <input type="radio" v-model="selectedPaylink" :value="paylink.id" name="paylinkSelection" />
                                            </td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ (currentPage - 1) * 15 + index + 1 }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ new Date(paylink.created_at).toLocaleDateString() }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ new Date(paylink.expiration_at).toLocaleDateString() }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.title }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.mode }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">{{ paylink.objet }}</td>
                                            <td :class="{ 'bg-warning': selectedPaylink === paylink.id }">
                                                <span v-if="paylink.status === 'pending'" class="badge rounded-pill text-bg-warning">En attente</span>
                                                <span v-if="paylink.status === 'approved'" class="badge rounded-pill text-bg-success">Approuvé</span>
                                                <span v-if="paylink.status === 'rejected'" class="badge rounded-pill text-bg-danger">Rejeté</span>
                                                <span v-if="paylink.status === 'expired'" class="badge rounded-pill text-bg-secondary">Expiré</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <nav aria-label="Page navigation">
                                    <ul class="pagination">
                                        <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                            <a class="page-link" @click="goToPage(currentPage - 1)" href="#" aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" :class="{ active: currentPage === page }" v-for="page in totalPages" :key="page">
                                            <a class="page-link" @click="goToPage(page)" href="#">{{page}}</a>
                                        </li>
                                        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                                            <a class="page-link" @click="goToPage(currentPage + 1)" href="#" aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row my-1">
                    <div class="col-lg-12 col-12">
                        <div class="custom-block bg-white">
                            <h5 class="mb-4">MES TRANSACTIONS</h5>
                            <hr> <!-- Horizontal line added here -->
                            <div class="table-responsive">
                                <table class="account-table table">
                                    <thead style="background-color: #006666">
                                        <tr>
                                            <th scope="col" style="color: white; text-align: center;">N°</th>
                                            <th scope="col" style="color: white; text-align: center;">DATE</th>
                                            <th scope="col" style="color: white; text-align: center;">EXPÉDITEUR</th>
                                            <th scope="col" style="color: white; text-align: center;">MONTANT</th>
                                            <th scope="col" style="color: white; text-align: center;">GSM</th>
                                            <th scope="col" style="color: white; text-align: center;">FONCTION</th>
                                            <th scope="col" style="color: white; text-align: center;">PAIEMENT</th>
                                            <th scope="col" style="color: white; text-align: center;">STATUT</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr v-if="loading">
                                            <td colspan="6" class="text-center">
                                                <div class="spinner-border text-success" role="status">
                                                    <span class="sr-only">Chargement en cours...</span>
                                                </div>
                                                <p>Chargement en cours...</p>
                                            </td>
                                        </tr>

                                        <tr v-if="!loading && filteredTransactions.length === 0">
                                            <td colspan="6" class="text-center">
                                                <div class="alert alert-warning d-flex justify-content-center align-items-center" role="alert">
                                                    Aucune donnée disponible.
                                                </div>
                                            </td>
                                        </tr>

                                        <tr v-for="(transaction, index) in filteredTransactions" :key="transaction.id">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ new Date(transaction.created_at).toLocaleDateString() }}</td>
                                            <td>{{ transaction.nomdestinataire }}</td>
                                            <td>{{ transaction.montant }}</td>

                                            <td>
                                                <img v-if="transaction.operateur === 'MTN'" src="/../../images/MOMOPAY.png" alt="MTN" style="width: 35px; height: 34px" />
                                                <img v-else-if="transaction.operateur === 'MOOV'" src="/../../images/MOOVFLOOZ.png" alt="MOOV" style="width: 35px; height: 34px" />
                                                <img v-else-if="transaction.operateur === 'CELTIIS'" src="/../../images/LOGOCELTIS.png" alt="CELTIIS" style="width: 35px; height: 34px" />
                                                <span v-else>{{ transaction.operateur }}</span>
                                            </td>

                                            <td>{{ transaction.fonction }}</td>
                                            <td>{{ transaction.payment_type }}</td>
                                            <td>
                                                <span v-if="transaction.status === 'PENDING'" class="badge text-bg-warning">
                                                    {{ transaction.status }}
                                                </span>
                                                <span v-else-if="transaction.status === 'SUCCESSFUL'" class="badge text-bg-success">
                                                    {{ transaction.status }}
                                                </span>
                                                <span v-else-if="transaction.status === 'FAILED'" class="badge text-bg-danger">
                                                    {{ transaction.status }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-end">
                                    <li class="page-item">
                                        <a class="page-link" href="#" @click.prevent="prevPage" :disabled="currentPage === 1">Précédent</a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link" href="#" @click.prevent="nextPage" :disabled="currentPage === totalPages">Suivant</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</div>
</template>

<style></style>
