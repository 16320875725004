<template>
    <main class="flex-shrink-0">
    
        <Head />
        <!-- Header -->
        <header class="py-5">
            <div class="container px-5">
                <div class="row gx-5 align-items-center">
                    <div class="col-xxl-5">
                        <div class="text-center text-xxl-start">
                            <p class="fs-3 fw-light text-dark">
                                La solution africaine de paiement et de suivi des transactions Money Money
                            </p>
                            <h1 class="display-5 fw-bolder mb-2">
                                <a href="/connexion" title="Commencer" role="button" class="btn btn-lg text-white" style="background-color: #006666; border-radius: 20px; padding: 12px 24px;">
                                    Commencer
                                </a>
                            </h1>
                        </div>
                    </div>
                    <div class="col-xxl-7">
                        <div class="d-flex justify-content-center mt-5 mt-xxl-0">
                            <div class="profile bg-gradient-primary-to-secondary text-center">
                                <img class="profile-img" src="images/LOGOAFRICAMONEY.png" alt="Logo Africamoney" />
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        </header>
    
        <section>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 mb-4 mb-lg-0">
                    <!-- Image cachée sur les petits écrans -->
                    <div class="d-none d-lg-block">
                        <img src="images/afrikmoney-iphone_mockup.webp" alt="iPhone Mockup" class="img-fluid position-relative" style="z-index: 1; max-width: 280px; margin-bottom: -100px;">
                    </div>
    
                </div>
    
                <div class="col-lg-6 position-relative">
                    <h4>Recevez de l'argent en toute sécurité avec un PayLink personnalisé.</h4>
                    <p class="mt-4">Avec votre paylink, gérer en toute simplicité le financement de vos projets et évènements, suivez vos collectes et recevez des paiements directement sur votre compte mobile money.</p>
                    <h4>Recevez votre reçu de paiement directement sur WhatsApp, simplement.</h4>
                    <a href="/paylink-user-registration" title="Commencer" role="button" class="btn btn-lg text-white" style="background-color: #006666; border-radius: 20px; padding: 12px 24px; margin-top: 20px;">
                        Commencer maintenant
                    </a>
                </div>
            </div>
        </div>
    </section>
    
    
        <br /><br /><br /><br /><br /><br />
    
        <section class="py-5 bg-light">
            <div class="container">
                <div class="text-center mb-5">
                    <h2 class="h3 fw-bold text-dark">Pourquoi utiliser un PayLinks ?</h2>
                    <p class="text-muted mt-3 mx-auto" style="max-width: 500px;">
    
                    </p>
                </div>
    
                <div class="row justify-content-center">
                    <!-- Step 1 -->
                    <div class="col-md-8">
                        <div class="d-flex align-items-start mb-5 position-relative">
                            <div class="bg-white shadow rounded-circle d-flex align-items-center justify-content-center flex-shrink-0" style="width: 64px; height: 64px; z-index: 1;">
    
                                <i class="fas fa-lock fa-3x mb-3"></i>
                            </div>
                            <div class="ms-4">
                                <h3 class="fs-5 fw-semibold text-dark">Sécurité maximale</h3>
                                <p class="text-muted mt-2">
                                    PayLinks garantit des transactions sécurisées et rapides pour vos collectes.
                                </p>
                            </div>
                        </div>
                    </div>
    
                    <!-- Step 2 -->
                    <div class="col-md-8">
                        <div class="d-flex align-items-start mb-5 position-relative">
                            <div class="bg-white shadow rounded-circle d-flex align-items-center justify-content-center flex-shrink-0" style="width: 64px; height: 64px; z-index: 1;">
    
                                <i class="fas fa-mobile-alt fa-3x mb-3"></i>
                            </div>
                            <div class="ms-4">
                                <h3 class="fs-5 fw-semibold text-dark">Facile d'utilisation</h3>
                                <p class="text-muted mt-2">
                                    Créez, partagez et gérez vos PayLinks depuis n'importe quel appareil.
                                </p>
                            </div>
                        </div>
                    </div>
    
                    <!-- Step 3 -->
                    <div class="col-md-8">
                        <div class="d-flex align-items-start mb-5">
                            <div class="bg-white shadow rounded-circle d-flex align-items-center justify-content-center flex-shrink-0" style="width: 64px; height: 64px;">
    
                                <i class="fas fa-globe fa-3x mb-3"></i>
                            </div>
                            <div class="ms-4">
                                <h3 class="fs-5 fw-semibold text-dark">Partout dans le monde</h3>
                                <p class="text-muted mt-2">
                                    Recevez des fonds de n'importe où, à tout moment, en quelques secondes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
        <section class="py-5 bg-white">
            <div class="container">
                <div class="row align-items-center g-5">
                    <!-- Contenu à gauche -->
                    <div class="col-lg-6 text-center text-lg-start">
                        <h3 class="fw-bold text-dark">Moyen de paiement.</h3>
                        <p class="mt-4 text-secondary">Collectez des fonds et faites-vous rembourser avec des moyens de paiement locaux tels que : MTN, MOOV, CELTIIS.</p>
                    </div>
    
                    <div class="col-lg-6 text-center">
                        <img class="img-fluid w-75" src="/images/afrikmoney_target.png" alt="Services Icons">
                    </div>
                </div>
            </div>
        </section>
    
        <section class="py-5 bg-light">
            <div class="container">
                <div class="text-center">
                    <h2 class="display-4 fw-bold text-dark">Comment ça marche ?</h2>
                </div>
    
                <div class="row mt-5">
                    <div class="col-md-4">
                        <div class="card shadow-sm border-light h-100 d-flex flex-column">
                            <div class="card-body text-center">
                                <!-- Centrer le numéro dans un cercle -->
                                <div class="d-flex justify-content-center align-items-center mb-3" style="background-color: #006666; color: white; width: 50px; height: 50px; font-size: 20px; border-radius: 50%;">
                                    1
                                </div>
                                <h5 class="fw-bold text-dark">Créez votre lien personnalisé</h5>
                                <p class="text-muted">Configurez un lien de paiement pour un événement en quelques clics.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card shadow-sm border-light h-100 d-flex flex-column">
                            <div class="card-body text-center">
                                <!-- Centrer le numéro dans un cercle -->
                                <div class="d-flex justify-content-center align-items-center mb-3" style="background-color: #006666; color: white; width: 50px; height: 50px; font-size: 20px; border-radius: 50%;">
                                    2
                                </div>
                                <h5 class="fw-bold text-dark">Partagez-le avec vos proches</h5>
                                <p class="text-muted">Envoyez votre lien PayLink via WhatsApp, email ou sur les réseaux sociaux.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card shadow-sm border-light h-100 d-flex flex-column">
                            <div class="card-body text-center">
                                <!-- Centrer le numéro dans un cercle -->
                                <div class="d-flex justify-content-center align-items-center mb-3" style="background-color: #006666; color: white; width: 50px; height: 50px; font-size: 20px; border-radius: 50%;">
                                    3
                                </div>
                                <h5 class="fw-bold text-dark">Remboursement automatique</h5>
                                <p class="text-muted">Suivez vos collectes et recevez des paiements directement sur votre compte.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
        <section class="py-5 bg-light">
        <div class="container">
            <div class="text-center mb-5">
                <h2 class="display-4 fw-bold text-dark">Foire aux questions (FAQ)</h2>
            </div>
            <div class="accordion" id="faqAccordion">
                <!-- Question 1 -->
                <div class="card shadow-lg border-0 rounded-5 mb-4">
                    <div class="card-header" style="background-color: #006666; color: black; border-radius: 0.75rem;" id="headingOne">
                        <h5 class="mb-0">
                            <button class="btn btn-link text-white fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Comment créer mon PayLink personnalisé ?
                            </button>
                        </h5>
                    </div>
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
                        <div class="card-body text-dark rounded-5" style="border-radius: 0.75rem;">
                            Pour créer votre PayLink personnalisé, il vous suffit de vous inscrire sur notre plateforme, de configurer votre lien de paiement, et de le partager avec vos proches ou sur les réseaux sociaux.
                        </div>
                    </div>
                </div>
                <!-- Question 2 -->
                <div class="card shadow-lg border-0 rounded-5 mb-4">
                    <div class="card-header" style="background-color: #006666; color: white; border-radius: 0.75rem;" id="headingTwo">
                        <h5 class="mb-0">
                            <button class="btn btn-link text-white fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Quels moyens de paiement sont acceptés ?
                            </button>
                        </h5>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
                        <div class="card-body text-dark rounded-5" style="border-radius: 0.75rem;">
                            Nous acceptons une variété de moyens de paiement, y compris les paiements via mobile money tels que MTN, MOOV, et CELTIIS.
                        </div>
                    </div>
                </div>
                <!-- Question 3 -->
                <div class="card shadow-lg border-0 rounded-5 mb-4">
                    <div class="card-header" style="background-color: #006666; color: white; border-radius: 0.75rem;" id="headingThree">
                        <h5 class="mb-0">
                            <button class="btn btn-link text-white fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Comment suivre mes collectes ?
                            </button>
                        </h5>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
                        <div class="card-body text-dark rounded-5" style="border-radius: 0.75rem;">
                            Vous pouvez suivre vos collectes en temps réel via votre tableau de bord sur notre plateforme. Vous recevrez des notifications lorsque des paiements sont effectués.
                        </div>
                    </div>
                </div>
                <!-- Question 4 -->
                <div class="card shadow-lg border-0 rounded-5 mb-4">
                    <div class="card-header" style="background-color: #006666; color: white; border-radius: 0.75rem;" id="headingFour">
                        <h5 class="mb-0">
                            <button class="btn btn-link text-white fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Puis-je recevoir mon reçu de paiement par WhatsApp ?
                            </button>
                        </h5>
                    </div>
                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
                        <div class="card-body text-dark rounded-5" style="border-radius: 0.75rem;">
                            Oui, après chaque paiement, vous recevrez votre reçu directement sur WhatsApp pour une gestion plus facile et rapide de vos transactions.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    
        <footer class="py-4 mt-auto" style="background-color: #006666; color: white">
            <div class="container">
                <div class="row align-items-center justify-content-center justify-content-sm-between text-center text-sm-start">
                    <div class="col-auto">
                        <p class="small text-white m-0">&copy; AfricaMoney {{ new Date().getFullYear() }}</p>
                    </div>
                    <div class="col-auto">
                        <a class="small text-white text-decoration-none mx-2" href="#!">Confidentialité</a>
                        <a class="small text-white text-decoration-none mx-2" href="#!">Termes et Conditions</a>
                    </div>
                </div>
            </div>
        </footer>
    </main>
    </template>
    
    <script>
    import Head from "@/components/Head.vue";
    import axios from "@/axs/axios.js";
    
    export default {
        components: {
            Head
        },
        data() {
            return {
                paylinkTotal: 0,
                totalMontant: 0,
                transactionCount: 0,
            };
        },
        mounted() {
            this.fetchPaylinkStats();
        },
        methods: {
            async fetchPaylinkStats() {
                try {
                    const response = await axios.get("/paylinks-total");
                    this.paylinkTotal = response.data.paylink_total;
                    this.totalMontant = response.data.total_montant;
                    this.transactionCount = response.data.transaction_count;
                } catch (error) {
                    console.error(
                        "Erreur lors de la récupération des statistiques :",
                        error
                    );
                }
            },
        },
    };
    </script>
    
    <style>
    .section-title {
        font-size: 2.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
    }
    
    .section-subtitle {
        font-size: 1.1rem;
        color: #666;
    }
    
    .card {
        transition: transform 0.3s ease-in-out;
    }
    
    .card:hover {
        transform: translateY(-10px);
    }
    
    .service-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        width: 3rem;
        margin: 0 auto;
    }
    
    .hero {
        background: black;
        color: white;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    </style>
    